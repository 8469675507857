import React, { Component } from "react";
import {isMobile} from 'react-device-detect';
import main_image from "../images/Kanonikes/11.jpg";

export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  render() {
    if (!isMobile){
    return (

          <div id="NoMarginNoPadding">
            
            <div id="about-color-margin">
              <p></p>
              <h5> <b>Η Ποδολόγος</b></h5>
              <p></p>

              
              <div className='row' id="NoMarginNoPadding" style={{marginTop:'2%'}}>
                <div className='col'  >
                <img src={main_image} id="Main_Image" alt="Erietta Moustaki About"></img>
                </div>
                <div className='col'>
                <p id="about-par"> 
                Ξεκίνησα την επαγγελματική μου δραστηριότητα ως φροντιστής ονύχων το 2004 στο κομμωτήριο Gi-EL, με παράλληλη κατ’ οίκον παροχή υπηρεσιών.<br></br>

Από το 2014 έως το 2018 μετεκπαιδεύτικα ως ποδολόγος στην <b>Ακαδημία Ποδολόγων Ελλάδος</b>, στα πλαίσια της οποίας έκανα πρακτική άσκηση στο <b>Νοσοκομείο Ανδρέας Συγγρός </b>.
Παράλληλα, το ίδιο χρονικό διάστημα (2014-2018) εκπαιδεύτηκα στην <b>Νοσηλευτική</b> και στην <b>Αισθητική</b>, διευρύνοντας τις γνώσεις μου και σε άλλα γνωστικά αντικείμενα, ώστε να είμαι σε θέση να παρέχω πληρέστερη φροντίδα στους πελάτες μου.<br></br>

Από το 2019 έως και σήμερα παρέχω τις επαγγελματικές μου υπηρεσίες σε προσωπικό χώρο στα Άνω Πετράλωνα.<br></br>

 </p>
                </div>
              </div>
                
            </div>
          </div>
    );}
    else{
      return(
        <div id="NoMarginNoPadding">
            <img src={main_image} id="Main_Image_Mobile" alt="Erietta Moustaki About"></img>
            <div id="about-color-margin">
              <p></p>
              <h5> <b>Η Ποδολόγος</b></h5>
              <p></p>
              
              <p id="about-par"> 
                Ξεκίνησα την επαγγελματική μου δραστηριότητα ως φροντιστής ονύχων το 2004 στο κομμωτήριο Gi-EL, με παράλληλη κατ’ οίκον παροχή υπηρεσιών.<br></br>

Από το 2014 έως το 2018 μετεκπαιδεύτικα ως ποδολόγος στην <b>Ακαδημία Ποδολόγων Ελλάδος</b>, στα πλαίσια της οποίας έκανα πρακτική άσκηση στο <b>Νοσοκομείο Ανδρέας Συγγρός </b>.
Παράλληλα, το ίδιο χρονικό διάστημα (2014-2018) εκπαιδεύτηκα στην <b>Νοσηλευτική</b> και στην <b>Αισθητική</b>, διευρύνοντας τις γνώσεις μου και σε άλλα γνωστικά αντικείμενα, ώστε να είμαι σε θέση να παρέχω πληρέστερη φροντίδα στους πελάτες μου.<br></br>

Από το 2019 έως και σήμερα παρέχω τις επαγγελματικές μου υπηρεσίες σε προσωπικό χώρο στα Άνω Πετράλωνα.<br></br>

 </p>

            </div>
        </div>
      );
    }
  }
}
