import React, { Component } from "react";
import Gallery from 'react-grid-gallery';
import k1 from "../images/Kanonikes/1.jpg";
import k2 from "../images/Kanonikes/2.jpg";
import k3 from "../images/Kanonikes/3.jpg";
import k4 from "../images/Kanonikes/4.jpg";
import k5 from "../images/Kanonikes/5.jpg";
import k6 from "../images/Kanonikes/6.jpg";
//import k7 from "../images/Kanonikes/7.jpg";
//import k8 from "../images/Kanonikes/8.jpg";
import k9 from "../images/Kanonikes/9.jpg";
//import k10 from "../images/Kanonikes/10.jpg";
import k11 from "../images/Kanonikes/11.jpg";
import k12 from "../images/Kanonikes/12.jpg";
//import k13 from "../images/Kanonikes/13.jpg";
//import k14 from "../images/Kanonikes/14.jpg";
import k15 from "../images/Kanonikes/15.jpg";
//import k16 from "../images/Kanonikes/16.jpg";
import k17 from "../images/Kanonikes/17.jpg";
//import k18 from "../images/Kanonikes/18.jpg";
import k19 from "../images/Kanonikes/19.jpg";
import k20 from "../images/Kanonikes/20.jpg";
//import k21 from "../images/Kanonikes/21.jpg";
import k22 from "../images/Kanonikes/22.jpg";
//import k23 from "../images/Kanonikes/23.jpg";
//import k24 from "../images/Kanonikes/24.jpg";
import k25 from "../images/Kanonikes/25.jpg";
import k26 from "../images/Kanonikes/26.jpg";



const images = [
  {src:k3,thumbnail:k3,caption:''},
  {src:k1,thumbnail:k1,caption:''},
  {src:k19,thumbnail:k19,caption:''},
  //{src:k24,thumbnail:k24,caption:''}, // Orthia



  {src:k11,thumbnail:k11,caption:''},
  //{src:k21,thumbnail:k21,caption:''}, // Orthia
  {src:k2,thumbnail:k2,caption:''},
  {src:k4,thumbnail:k4,caption:''},


  
  {src:k20,thumbnail:k20,caption:''},
  //{src:k23,thumbnail:k23,caption:''}, // Orthia
  {src:k17,thumbnail:k17,caption:''},
  {src:k5,thumbnail:k5,caption:''},


  
  {src:k6,thumbnail:k6,caption:''},
  {src:k26,thumbnail:k26,caption:''},
  {src:k22,thumbnail:k22,caption:''},
  //{src:k16,thumbnail:k16,caption:''}, // Orthia
  {src:k9,thumbnail:k9,caption:''},
  {src:k25,thumbnail:k25,caption:''},
  {src:k15,thumbnail:k15,caption:''},

  {src:k12,thumbnail:k12,caption:''}


  
]


export default class MyGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  render() {
   return (

      <div id="NoMarginNoPadding">
         <div id="gallery-margin">
            <p></p>
               <h5> <b>Ο Χώρος μας</b></h5>
            <p></p>     
         </div>
         <div id="Gallery_margin">
         <Gallery title='Gallery'  images={images} enableImageSelection={false} backdropClosesModal={true} rowHeight="15vw" preloadNextImage={true} margin="0.3vw"  />
         </div>
      </div>
    );
  }
}
