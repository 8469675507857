import React, { Component} from "react";
import CookieConsent from "react-cookie-consent";
import './App.css';
import { Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
//images
//import Logo from './images/logo_only_letters.png'
//import Logo from './images/logo_and_letters.png'
import Logo from './images/logo_only.png'
//import Logo from './images/my_logo_cropped.png'
// components
import About from "./components/About.component";
import Contact from "./components/Contact.component";
import MyGallery from "./components/Gallery.component";
import Services from "./components/Services2.component";
import Timetable from "./components/Timetable.component";
import Footer from "./components/Footer.component";



class App extends Component {
  constructor(props) {
    super(props);
    

    this.state = {

    };
  }
  componentDidMount () {
    
    
    
  
  }
  
  componentWillUnmount() {
    
}


  render(){

  return (
    <div className="App" >
      <CookieConsent
        location="bottom"
        buttonText="Εντάξει"
        cookieName="myAwesomeCookieName2"
        style={{ opacity: "0.8" , background: "#27272D" }}
        buttonStyle={{ "border-radius": "5px", background: "silver", color: "black", fontSize: "0.7em" }}
        expires={150}
      >
        <span style={{ fontSize: "0.7em" }}>Χρησιμοποιούμε cookies για να σας προσφέρουμε την καλύτερη δυνατή εμπειρία στη σελίδα μας. Εάν συνεχίσετε να χρησιμοποιείτε τη σελίδα, θα υποθέσουμε πως είστε ικανοποιημένοι με αυτό :)</span>
      </CookieConsent>
      <div id="hidden"> <h1>Ποδολόγος Εριέττα Μουστάκη</h1> </div>
      <div>
      <nav id="NavBar"  className="nav navbar responsive-navbar-nav  navbar-expand-lg navbar-light bg-light sticky-top flex-md-row  responsive-navbar-nav navbar-inverse"  role="navigation">
      
      

        
        


        <form className="form-inline my-2 my-lg-0 navbar-header">

          <div> <a className="nav-brand" href="/"><img id='logo' alt='Erietta Moustaki Podologos Logo' src={Logo} /></a></div>        
        </form>

        <div id="Contents" className='menu-container' >
      
      <div className="navbar-header">
      <button type="button" className="navbar-toggler navbar-toggler-right"  data-toggle="collapse" data-target=".navbar-collapse" aria-expanded="false" aria-controls="navbarNavAltMarkup"  >
      <span className="navbar-toggler-icon"></span>
      </button>

      </div>
      
      <div className="col"></div>
      


    <div className="navbar-actions">
      <div className="navbar-collapse responsive-navbar-nav collapse" aria-expanded="false"  id="navbarNavAltMarkup">
      
      <ul className='nav navbar-nav navbar-nav-right ' data-split-after="5">
        
        <li>   
            <a className="nav-item nav-link" href="#About">Η Ποδολόγος</a>
        </li>
        <li>   
            <a className="nav-item nav-link" href="#Gallery">Ο Χώρος μας</a>
        </li>
        <li>   
            <a className="nav-item nav-link" href="#Services">Υπηρεσίες</a>
        </li>
        <li>   
            <a className="nav-item nav-link" href="#Timetable">Ωράριο</a>
        </li>
        <li>   
            <a className="nav-item nav-link" href="#Contact">Επικοινωνία</a>
        </li>
      </ul>
  </div>
  </div>
  </div>

        </nav>
        
      


        
        

        <div id="AllComponents" className="">
        <div id='About' >
        
          <Route  component={About} />
        </div>


        <div id='Gallery'>

        <Route  component={MyGallery} />
        </div>

        <div id='Services'>

        <Route  component={Services} />
        </div>


        <div id='Timetable'>

        <Route  component={Timetable} />
        </div>



        <div id='Contact'>

        <Route  component={Contact} />
        </div>
        <div id='Footer'>

        <Route  component={Footer} />

      
        </div>
        </div>
    </div>
    </div>
  );
}
}
export default App;
