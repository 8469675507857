import React, { Component } from "react";
import { SocialIcon } from 'react-social-icons';

export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  render() {
    return (

          <div id="NoMarginNoPadding">
            <div id="Footer_margin">
            <div id="Footer_color">
            <div id="Footer_letters">
               <div className="row">

               <div className="col" id="text_left">
                  <p>Ακολουθήστε μας:</p>
                  <div id="Social" className='row'>
                    <div id="fb" className="col">
                  
                  <SocialIcon target="_blank" rel="noreferrer" network="facebook"  url="https://www.facebook.com/EriettaMoustakiPodology" />
                    </div>
                    <div className="col">

                  <SocialIcon target="_blank" rel="noreferrer" network="instagram" url="https://www.instagram.com/moustaki_erietta/" />
                    </div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                  </div>

               </div> 
               <div className="col" id="text_right">
                  <p>Επικοινωνία:</p>
                  <p>+30 695 5056401</p>
                  <p>Κειριαδών 57, Αθήνα 11853</p>
                  </div> 
               </div>
               <div id="Rights">
               <p>All rights reserved @eriettamoustaki</p>
               <p></p><p></p><p></p><p></p>
               </div>
               
               

            </div>
            </div>
            </div>
          </div>
    );
  }
}
