import React, { Component } from "react";
import {isMobile} from 'react-device-detect';
export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }


  render() {
    if(!isMobile){
    return (
      <div id="NoMarginNoPadding">
            
      <div id="timetable-margin">
        <p></p>
        <h5> <b>Επικοινωνία</b></h5>
        <p></p>
      </div>  
      <div id="ContactFrame" className="row align-items-center">
      <div id="Maps_Iframe_margin" className="col">
      <iframe title="Google Maps Erietta Moustaki" id="Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3145.2659005048995!2d23.70937!3d37.97092349999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bd5aac3a0537%3A0x33fa29764d4ad1a3!2zzqDOv860zr_Ou8-MzrPOv8-CIM6Vz4HOuc6tz4TPhM6xIM6czr_Phc-Dz4TOrM66zrc!5e0!3m2!1sel!2sgr!4v1681042588917!5m2!1sel!2sgr"  allowfullscreen="" frameborder="0" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

      </div>
        <div id="Contact_Borders" className="col">
            
          <a href="https://goo.gl/maps/9i6Wop8dBeMvdNPEA">  📍 Κειριαδών 57, Αθήνα 11853</a>
          <p></p> <p></p> <p></p>
          <a href="tel:+30-695-5056401" > 📞 +30 695 5056401</a>
          <p></p> <p></p> <p></p>
          <a href="mailto:erietta_moustaki@hotmail.com"> 📧 erietta_moustaki@hotmail.com</a>
          <p></p> <p></p> <p></p>
         </div>
         </div>
    </div>
    );}
    else{return(
      <div id="NoMarginNoPadding">
            
      <div id="timetable-margin">
        <p></p>
        <h5> <b>Επικοινωνία</b></h5>
        <p></p>
      </div>  
      <div id="Maps_Iframe_margin" >
        

      <iframe title="Google Maps Erietta Moustaki"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3145.2659005048995!2d23.70937!3d37.97092349999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bd5aac3a0537%3A0x33fa29764d4ad1a3!2zzqDOv860zr_Ou8-MzrPOv8-CIM6Vz4HOuc6tz4TPhM6xIM6czr_Phc-Dz4TOrM66zrc!5e0!3m2!1sel!2sgr!4v1681042588917!5m2!1sel!2sgr"  allowfullscreen="" frameborder="0" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      <p></p>
      <p></p>
      <p></p>
      </div>
        <div id="Contact_Borders" >
            
          <a href="https://goo.gl/maps/9i6Wop8dBeMvdNPEA">  📍 Κειριαδών 57, Αθήνα 11853</a>
          <p></p> <p></p> <p></p>
          <a href="tel:+30-695-5056401" > 📞 +30 695 5056401</a>
          <p></p> <p></p> <p></p>
          <a href="mailto:erietta_moustaki@hotmail.com"> 📧 erietta_moustaki@hotmail.com</a>
          <p></p> <p></p> <p></p>
         </div>
    </div>
    );}
  }
}
