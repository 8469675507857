import React, { Component } from "react";
//import timetable_image from "../images/timetable.jpg";
import m24 from "../images/Kanonikes/16.jpg";
export default class Timetable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }


  render() {
    return (
      <div id="NoMarginNoPadding">
            
      <div id="timetable-margin">
        <p></p>
        <p> <h5><b>Ωράριο</b></h5></p>
        <p></p>
      </div>  
      <div id="NoMarginNoPadding" className="row align-items-center">
      <div id="Timetable_image" className="col">
         <img  src={m24} width="100%" alt="Erietta Moustaki Timetable" />
      </div>
        <div id="Timetable_Borders" className="col">
            
          <p> <p> <b>Δευτέρα</b> </p>09:00 - 19:00</p>
          <p></p> <p></p> <p></p>
          <p> <p> <b>Τρίτη</b></p>09:00 - 19:00</p>
          <p></p> <p></p> <p></p>
          <p> <p> <b>Τετάρτη</b> </p>09:00 - 19:00</p>
          <p></p> <p></p> <p></p>
          <p> <p> <b>Πέμπτη</b> </p>09:00 - 19:00</p>
          <p></p> <p></p> <p></p>
          <p> <p> <b>Παρασκευή</b> </p>09:00 - 19:00</p>
          <p></p> <p></p> <p></p>
          <p> <p> <b>Σάββατο</b> </p>10:00 - 15:00</p>
          <p></p> <p></p> <p></p>
          <p> <p> <b>Κυριακή</b> </p>Κλειστά</p>
          <p></p> <p></p> <p></p>
         </div>
         </div>
    </div>
    );
  }
}
