import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import mirmiges from "../images/pathisis/mirmiges.jpg";
import kaloi from "../images/pathisis/kaloi.jpg";
import onixogrypwsh from "../images/pathisis/onixogrypwsh.jpg";
import onixokryptwsh from "../images/pathisis/onixokryptwsh.jpg";
import onyxomikitiasi from "../images/pathisis/onyxomikitiasi.jpg";
import orthonyxia from "../images/pathisis/orthonyxia.jpg";
import ostrakoeidis_onyxes from "../images/pathisis/ostrakoeidis_onyxes.jpg";
import yperkeratwseis from "../images/pathisis/yperkeratwseis.jpg";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [expandedd, setExpandedd] = React.useState('');

  const handleChangee = (panel) => (event, newExpandedd) => {
    setExpandedd(newExpandedd ? panel : false);
  };

  return (
   <div id="Services_margin">
   <div id="Services_header">
     <p></p>
     <p><h5> <b>Υπηρεσίες</b></h5></p>
     <p></p>
   </div>

    <div className='row' style={{marginLeft:'1%',marginRight:'1%'}} >
       <div className='col' >

       
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography  fontFamily='Open Sans'fontSize='1.2em' >Ονυχοκρύπτωση</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className='row'>
        <div className='col'>
        <img width="100%" alt="Ονυχοκρύπτωση"  src={onixokryptwsh}/>
        </div>
        <div className='col' id='CollapseContent'>
        Η ονυχοκρύπτωση είναι η βλάβη κατά την οποία παρεκκλινόμενο τμήμα όνυχα εισχωρεί στα μαλακά μόρια του δέρματος και προκαλεί φλεγμονή, οίδημα, πυόρια, άλγος. Προκαλείται από λάθος κοπή των ονύχων ,πίεση από τα υποδήματα, κληρονομικότητα, υπερυδρωσία κ.α.
      </div>
        </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography  fontFamily='Open Sans'fontSize='1.2em' >Ονυχομυκητιάσεις</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className='row'>
        <div className='col'>
        <img width="100%" alt="Ονυχομυκητιάσεις"  src={onyxomikitiasi}/>
        </div>
        <div className='col' id='CollapseContent'>
        Η ονυχομυκητίαση είναι μια λοίμωξη των νυχιών. Είναι χρόνια και προσβάλει μερικό ή ολικό τμήμα του όνυχα. Ακόμα και τη μήτρα αυτού. Ο όνυχας εξελικτικά αλλάζει χρώμα, σχήμα (πάχυνση) και προκαλεί κακοσμία.
      </div>
        </div>
        </AccordionDetails>
      </Accordion>
      

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography  fontFamily='Open Sans'fontSize='1.2em' >Ονυχογρύπωση</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className='row'>
        <div className='col'>
        <img width="100%" alt="Ονυχογρύπωση"  src={onixogrypwsh}/>
        </div>
        <div className='col' id='CollapseContent'>
        Η ονυχογρύπωση δύναται να παρουσιαστεί κυρίως σε άτομα τρίτης ηλικίας. Τα νύχια αποκτούν πάχυνση, υπερκυρτότητα, πολλαπλές εγκάρσιες γραμμές. Κατά τη βάδιση υπάρχει πόνος. Η απουσία περιποίησης των άκρων, τραυματισμοί ακόμα και οστικές αλλοιώσεις προκαλούν τη βλάβη αυτή.
      </div>
        </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
          <Typography  fontFamily='Open Sans'fontSize='1.2em' >Οστρακοειδής Όνυχες</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className='row'>
        <div className='col'>
        <img width="100%" alt="Οστρακοειδής Όνυχες"  src={ostrakoeidis_onyxes}/>
        </div>
        <div className='col' id='CollapseContent'>
        Ο οστρακοειδής όνυχας είναι μία δυστροφία η οποία απαντάται με τη μορφή οστράκου. Οι όνυχες υπερκυρτώνουν ιδιαίτερα προς το ελεύθερο άκρο. Υπάρχει περίσφιξη της κοίτης. Οι όνυχες εισχωρούν στο δέρμα προκαλώντας δυσφορία και πόνο κατά τη βάδιση.
      </div>
        </div>
        </AccordionDetails>
      </Accordion>

      </div>
      <div className='col'  >
      
      <Accordion expanded={expandedd === 'panel2'} onChange={handleChangee('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography  fontFamily='Open Sans'fontSize='1.2em' >Ορθονυχία</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className='row'>
        <div className='col'>
        <img width="100%" alt="Ορθονυχία"  src={orthonyxia}/>
        </div>
        <div className='col' id='CollapseContent'>
        Είναι η τεχνική που χρησιμοποιεί ο ποδολόγος για την άμεση επανέκφυση του όνυχα σε ορθή πορεία όταν αυτό παρεκκλίνει και εισχωρεί προς το δέρμα. Αυτό μπορεί να προκαλέσει φλεγμονή, άλγος, ερυθρότητα, οίδημα. Η τεχνική προσφέρει άμεση ανακούφιση.
      </div>
        </div>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expandedd === 'panel7'} onChange={handleChangee('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography  fontFamily='Open Sans'fontSize='1.2em' >Υπερκερατώσεις</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className='row'>
        <div className='col'>
        <img width="100%" alt="Υπερκερατώσεις"  src={yperkeratwseis}/>
        </div>
        <div className='col' id='CollapseContent'>
        Οι υπερκερατώσεις είναι η φυσιολογική αντίδραση του δέρματος για να προστατευθεί από την αυξημένη πίεση που ασκείται κατά τη  διάρκεια μιας συνεχούς μηχανικής πίεσης π.χ ορθοστασία, υποδήματα στα οποία το βάρος του σώματος πέφτει στα μετατάρσια. Η κατάσταση αυτή είναι επίπονη για τον ασθενή. 
      </div>
        </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expandedd === 'panel8'} onChange={handleChangee('panel8')}>
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <Typography  fontFamily='Open Sans'fontSize='1.2em' >Κάλοι, Τύλοι</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className='row'>
        <div className='col'>
        <img width="100%" alt="Κάλοι, Τύλοι"  src={kaloi}/>
        </div>
        <div className='col' id='CollapseContent'>
        Ο Κάλος ή τύλος είναι επίκτητη διαδικασία κατά την οποία το δέρμα για να προστατευθεί από συνεχή πίεση σε ένα σημείο, προκαλεί αυτό το προστατευτικό κερατινοποιημένο κάλυμμα. Διαθέτουν πυρήνα ο οποίος προκαλεί οξύ πόνο. Τους συναντάμε στο πέλμα, στα δάχτυλα, φάλαγγες και μεσοδακτύλια και στις αύλακες στις πλαγιές πτυχές των ονύχων (ονυχοφωση).
      </div>
        </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expandedd === 'panel5'} onChange={handleChangee('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography  fontFamily='Open Sans'fontSize='1.2em' >Μυρμηγκιές, Ακροχορδώνες</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className='row'>
        <div className='col'>
        <img width="100%" alt="Μυρμηγκιές, Ακροχορδώνες"  src={mirmiges}/>
        </div>
        <div className='col'id='CollapseContent'>
        Οι μυρμηγκιές είναι καλοήθεις υπερπλασίες του δέρματος και προκαλούνται από τον ιο των ανθρωπίνων θηλωμάτων. Παρουσιάζονται στα άκρα και προκαλούν δυσφορία και πόνο. Μέρη στα οποία υπάρχει υγρασία ο ιός αναπτύσσεται και ευδοκιμεί π.χ. αμμουδιά, δημόσια ντους. Η αρχική τους εικόνα μοιάζει με τους κάλλους/τύλους και γι αυτό πολλές φορές παρερμηνεύεται. Με τη σωστή διάγνωση και αντιμετώπιση ο ασθενής απαλλάσσεται από αυτές.
      </div>
        </div>
        </AccordionDetails>
      </Accordion>
      
      </div>
    </div>
    </div>


  );
}